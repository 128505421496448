.home-link {
    @apply text-blue-text underline underline-offset-4
}

.home-container {
    @apply flex my-14 justify-center items-center text-gray-text w-full flex-1 bg-main-dark-bg
}

.home-content {
    @apply flex flex-col gap-1 text-base max-w-7xl p-4
}

.home_text {
    @apply text-2xl text-white
}

.home-head_text {
    @apply text-7xl text-white
}

.home-subhead_text {
    @apply mb-10 text-4xl text-blue-text
}
