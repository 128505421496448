.form {
    @apply flex flex-col w-full max-w-md
}

.label {
    @apply flex flex-col mb-4 gap-1
}

.input {
    @apply w-full outline-none p-1.5 bg-second-dark-bg border text-white-text
    border-secondary-gray rounded focus:border-gray-text focus:shadow focus:shadow-secondary-gray
    placeholder:text-secondary-gray
}

.disabled {
    @apply cursor-not-allowed text-secondary-gray
}
