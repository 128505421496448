@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Fira Code', monospace;
    background-color: #1D2434;
    color: #A0AABE;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.contacts-icon {
    @apply w-4 h-4 object-contain
}

.footer-link-icon {
    @apply w-6 h-6 object-contain
}

.skill-icon {
    @apply w-16 h-16 object-contain
}

.filter-icon {
    @apply w-4 h-4 object-contain mr-1
}

.flex-center {
    @apply flex justify-center items-center
}
